// Copyright 1999-2018. Plesk International GmbH. All rights reserved.

import { createElement } from 'react';

import PropTypes from 'prop-types';
import { Switch as BaseSwitch, Route } from 'react-router-dom';

export const Switch = ({ children }) => {
    const ExternalRedirect = () => {
        location.reload();

        return null;
    };

    return (
        <BaseSwitch>
            {children}
            <Route component={ExternalRedirect} />
        </BaseSwitch>
    );
};

Switch.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Switch;
