// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { Popup } from './popup';
import { LEFT_ARROW, RIGHT_ARROW } from './keyCode';

export class PopupSlider extends Popup {
    _initConfiguration(config) {
        super._initConfiguration({
            popupCls: 'popup-panel popup-panel-slider popup-panel-auto',
            closeButtonEnabled: true,
            hideOnEscape: true,
            ...config,
        });

        this._slides = this._getConfigParam('slides', []);
        this._current = this._getConfigParam('current', 0);
    }

    _getContentArea() {
        const name = `slider-${this.getId()}`;

        return (
            `<div class="slider infinity">${
                this._slides
                    .map((item, index) => (
                        `<input type="radio" class="slider-check" name="${name}" id="${name}_${index + 1}"` +
                            ` ${index === this._current ? 'checked="checked"' : ''}>`
                    ))
                    .join('')}` +
                `<ul class="slider-container">${
                    this._slides
                        .map(item => `<li class="slider-item">${item}</li>`).join('')
                }</ul>` +
                `<div class="slider-arrows">${
                    this._slides
                        .map((item, index) => `<label class="slider-label" for="${name}_${index + 1}"></label>`)
                        .join('') +
                    (this._slides.length > 1 ? (
                        `<label class="slider-label goto-first" for="${name}_1"></label>` +
                        `<label class="slider-label goto-last" for="${name}_${this._slides.length}"></label>`
                    ) : '')
                }</div>` +
                '<div class="slider-navigation">' +
                    `<div class="slider-navigation-inner">${
                        this._slides
                            .map((item, index) => `<label class="slider-label" for="${name}_${index + 1}"></label>`)
                            .join('')
                    }</div>` +
                '</div>' +
            '</div>'
        );
    }

    _onKeyDown(e) {
        super._onKeyDown(e);
        const slides = [...document.querySelectorAll('.slider-check')];
        const currentIndex = slides.indexOf(document.querySelector('.slider-check:checked'));
        switch (e.keyCode) {
            case LEFT_ARROW:
                slides[currentIndex === 0 ? slides.length - 1 : currentIndex - 1].checked = true;
                break;
            case RIGHT_ARROW:
                slides[currentIndex === slides.length - 1 ? 0 : currentIndex + 1].checked = true;
                break;
        }
    }
}
