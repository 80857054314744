/* eslint-disable react/jsx-max-depth */
// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { createElement, Component, StrictMode, Fragment } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Switch } from 'react-router-dom';
import ConfigContext from './ConfigContext';
import UserContext from './UserContext';
import RouteRequire from './RouteRequire';
import Login from './Login';
import GetPassword from './GetPassword';
import RestorePassword from './RestorePassword';

class App extends Component {
    state = {
        widgets: [],
    };

    getConfirmation = (message, callback) => {
        try {
            const { callback: callbackReturnValue } = JSON.parse(message);

            if (typeof callbackReturnValue !== 'undefined') {
                callback(callbackReturnValue);
            } else {
                throw new Error();
            }
        } catch (e) {
            // eslint-disable-next-line no-alert
            callback(window.confirm(message));
        }
    };

    addWidget(widget) {
        this.setState(({ widgets }) => ({
            widgets: [
                ...widgets,
                widget,
            ],
        }));
    }

    render() {
        return (
            <StrictMode>
                <ConfigContext.Provider value={this.props.config}>
                    <UserContext.Provider value={this.props.user}>
                        <BrowserRouter getUserConfirmation={this.getConfirmation}>
                            <Fragment>
                                <Switch>
                                    <RouteRequire
                                        path={['/login_up.php', '/login_up.php3', '/login']}
                                        dataPath="/login_up.php"
                                        component={() => Login}
                                    />
                                    <RouteRequire path="/get_password.php" dataPath="/get_password.php" component={() => GetPassword} />
                                    <RouteRequire path="/ch_pass_by_secret.php" dataPath="/ch_pass_by_secret.php" component={() => RestorePassword} />
                                </Switch>
                                {this.state.widgets}
                            </Fragment>
                        </BrowserRouter>
                    </UserContext.Provider>
                </ConfigContext.Provider>
            </StrictMode>
        );
    }
}

App.propTypes = {
    config: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

export default App;
