// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { createContext, useContext } from 'react';

const UserContext = createContext({
    isAdmin: false,
    email: undefined,
});

export default UserContext;
export const useUserContext = () => useContext(UserContext);
