// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { createElement } from 'react';

import { render } from 'react-dom';
import App from './components/App';

if (window.location.pathname.indexOf('/cp/') === 0) {
    render(
        <App />,
        document.getElementById('app')
    );
}
