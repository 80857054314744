// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

/* eslint-disable react/jsx-max-depth */

import { createElement, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Tooltip, LocaleProvider, AutoClosable } from '@plesk/ui-library';
import { prepareUrl } from 'jsw';
import { useLocalStorage, useMediaQuery } from 'common/hooks';
import ViewSwitcher from './ViewSwitcher';
import Brand from './Brand';

const Node = ({ id, styleClass, active, name, conhelp, link, target, custom, icon, onClick }) => (
    <Tooltip title={conhelp}>
        <li
            id={id || null}
            className={classNames(!id && styleClass, active && 'active')}
        >
            <a href={prepareUrl(link)} target={target} onClick={onClick}>
                <i
                    className={styleClass ? `icon-${styleClass.split(' ')[0]}` : null}
                    style={custom ? { backgroundImage: `url('${icon}')` } : null}
                />
                <span className="title">{name}</span>
            </a>
        </li>
    </Tooltip>
);

Node.propTypes = {
    id: PropTypes.string,
    styleClass: PropTypes.string,
    active: PropTypes.bool,
    name: PropTypes.string.isRequired,
    conhelp: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    target: PropTypes.string,
    custom: PropTypes.bool,
    icon: PropTypes.string,
    onClick: PropTypes.func,
};

Node.defaultProps = {
    id: undefined,
    styleClass: undefined,
    active: false,
    target: undefined,
    custom: false,
    icon: undefined,
    onClick: undefined,
};

const Group = ({ id, showTitle, name, nodes, isSidebarClosed, onNodeClick }) => {
    const group = id.toString().replace('_', '-');
    const [isOpen, setOpen] = useLocalStorage(`menu-group-${group}-hidden`);

    let title = (
        <a className="menu-group-title" onClick={() => setOpen(isOpen === 'true' ? 'false' : 'true')}>
            <span className="menu-group-toggle" />
            <span className="menu-group-name">{name}</span>
        </a>
    );

    if (isSidebarClosed && name) {
        title = (
            <Tooltip title={name}>
                {title}
            </Tooltip>
        );
    }

    return (
        <li key={id} className={classNames(`menu-group-${group}`, isOpen === 'true' ? 'close' : 'open')}>
            {showTitle ? title : null}
            {nodes && Object.keys(nodes).length ? (
                <ul className="sub-menu">
                    {Object.keys(nodes).map(key => {
                        const { conhelp, ...props } = nodes[key];
                        return (
                            <Node
                                key={key}
                                conhelp={isSidebarClosed ? props.name : conhelp}
                                onClick={onNodeClick}
                                {...props}
                            />
                        );
                    })}
                </ul>
            ) : null}
        </li>
    );
};

Group.propTypes = {
    id: PropTypes.string.isRequired,
    showTitle: PropTypes.bool.isRequired,
    name: PropTypes.string,
    nodes: PropTypes.object.isRequired,
    isSidebarClosed: PropTypes.bool.isRequired,
    onNodeClick: PropTypes.func,
};

Group.defaultProps = {
    onNodeClick: null,
    name: null,
};

const PageSidebar = ({ title, logo, navigation, viewSwitcher }) => {
    const togglerRef = useRef();
    const [isClosedOnDesktop, setClosedOnDesktop] = useLocalStorage('isSidebarClosed');
    const [isClosedInResponsive, setClosedInResponsive] = useState(true);
    const isResponsive = useMediaQuery('(max-width: 1022px)');
    const isClosed = isResponsive ? isClosedInResponsive : isClosedOnDesktop === 'true';

    useLayoutEffect(() => {
        document.querySelector('.pul-layout').classList[isClosed ? 'add' : 'remove']('pul-layout--sidebar-folded');
        document.querySelector('.pul-layout__sidebar').classList[isClosed ? 'add' : 'remove']('pul-layout__sidebar--off');
        document.querySelector('.pul-layout__sidebar').classList[!isClosed ? 'add' : 'remove']('pul-layout__sidebar--on');
    }, [isClosed]);

    const handleToggleSidebar = () => {
        if (isResponsive) {
            setClosedInResponsive(!isClosedInResponsive);
        } else {
            setClosedOnDesktop(isClosedOnDesktop === 'true' ? 'false' : 'true');
        }
    };

    const renderMenu = () => (
        <ul className="page-sidebar-menu">
            {navigation.map(({ id, ...props }) => (
                <Group
                    key={id}
                    id={id}
                    isSidebarClosed={isClosed}
                    onNodeClick={isResponsive ? () => setClosedInResponsive(true) : null}
                    {...props}
                />
            ))}
        </ul>
    );

    const renderViewSwitcher = () => {
        if (!viewSwitcher) {
            return null;
        }

        const { locale, ...props } = viewSwitcher;
        return (
            <LocaleProvider messages={locale}>
                <ViewSwitcher {...props} />
            </LocaleProvider>
        );
    };

    const sidebar = (
        <div className="pul-layout__sidebar-inner">
            <div className="pul-layout__sidebar-toggler" onClick={handleToggleSidebar}>
                <div className="pul-layout__sidebar-toggler-inner" />
            </div>

            {createPortal((
                <button
                    className={classNames(
                        'pul-layout__responsive-sidebar-toggler',
                        !isClosedInResponsive && 'pul-layout__responsive-sidebar-toggler--open'
                    )}
                    type="button"
                    ref={togglerRef}
                    onClick={handleToggleSidebar}
                >
                    <span className="pul-layout__responsive-sidebar-toggler-inner">
                        <span className="pul-layout__responsive-sidebar-toggler-icon" />
                    </span>
                </button>
            ), document.querySelector('.pul-layout__header-content'))}

            <div className="pul-layout__sidebar-content">
                <div className="pul-layout__sidebar-content-inner">

                    <div
                        className={classNames(
                            'page-sidebar',
                            isClosed && 'page-sidebar--folded'
                        )}
                        data-type="page-sidebar"
                    >
                        <div className="page-sidebar__content">
                            <div className="page-sidebar-brand">
                                {isClosed ? (
                                    <div className="brand-collapsed">{title[0] || 'P'}</div>
                                ) : (
                                    <Brand {...logo} />
                                )}
                            </div>

                            <div className="page-sidebar-menu-wrapper">
                                {renderMenu()}
                            </div>
                        </div>
                        <div className="page-sidebar-footer-wrapper">
                            <div className="page-sidebar-footer">
                                {renderViewSwitcher()}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );

    if (!isResponsive) {
        return sidebar;
    }

    return (
        <AutoClosable onClose={() => setClosedInResponsive(true)} ignoreNodes={() => [togglerRef.current]}>
            {sidebar}
        </AutoClosable>
    );
};

PageSidebar.propTypes = {
    title: PropTypes.string.isRequired,
    logo: PropTypes.object.isRequired,
    navigation: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        showTitle: PropTypes.bool,
        name: PropTypes.string,
        nodes: PropTypes.object,
    })).isRequired,
    viewSwitcher: PropTypes.shape({
        locale: PropTypes.object,
    }),
};

PageSidebar.defaultProps = {
    viewSwitcher: undefined,
};

export default PageSidebar;
